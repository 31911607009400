import React from 'react'
import Page from '../components/Page'
import ReactCountryFlag from "react-country-flag"
const style={fontSize: '3em',lineHeight: '2em'}

const LanguagePage = () => (
  
  <Page title="Languages">
      <p className="mt-8 w-2/3 text-center card">
        Our certified translators serve our customers in patent translations in nine languages:<br/>
        <br/>
        CN - JP - KR - EN - ES - DE - IT - NL - FR<br/>
        <br/>
        The target language is always the translator’s mother tongue.<br/>
      </p>
      <div className="mt-4 grid grid-cols-3 md:grid-cols-9">
        <ReactCountryFlag countryCode="CN" style={style} className="p-2 bg-gray-100"/>
        <ReactCountryFlag countryCode="JP" style={style} className="p-2 bg-gray-100"/>
        <ReactCountryFlag countryCode="KR" style={style} className="p-2 bg-gray-100"/>
        <ReactCountryFlag countryCode="GB" style={style} className="p-2 bg-gray-100"/>
        <ReactCountryFlag countryCode="ES" style={style} className="p-2 bg-gray-100"/>
        <ReactCountryFlag countryCode="DE" style={style} className="p-2 bg-gray-100"/>
        <ReactCountryFlag countryCode="IT" style={style} className="p-2 bg-gray-100"/>
        <ReactCountryFlag countryCode="NL" style={style} className="p-2 bg-gray-100"/>
        <ReactCountryFlag countryCode="FR" style={style} className="p-2 bg-gray-100"/>
      </div>
  </Page>
)

export default LanguagePage